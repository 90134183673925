import React from 'react';
import NavBar from './NavBar';
import InlineConnectedImages from './InlineConnectedImages';
import ContactInfo from './ContactInfo';

function App() {
  return (
    <div className="App">
      <NavBar />  {/* Include the NavBar at the top */}
      <div className="App-body">
        <p style={{ backgroundColor: 'rgb(115, 206, 226)', padding: '20px', color: '#333', margin: '0px', borderRadius: '0px' }}>
      
        <h4 id="about" style={{ textAlign: 'center', color: '#333', textTransform: 'uppercase' }}>On the Ragged Edge of Technology</h4>
        We're in the vanguard of pioneering cloud technology with AWS. If we haven't done it, there's a good chance that no one else has either. But our ethics drive us not just into the frontier and beyond what we can see on the horizon, but to produce quality custom solutions that actually solve your problems making your company more lightweight and cost-efficient.
        
        <img src="/main.png" alt="Descriptive Text for Image" style={{ width: '100%', height: 'auto', display: 'block', margin: '20px auto' }} />
        </p>

        <p style={{ backgroundColor: 'rgb(139, 222, 240)', padding: '20px', color: '#333', margin: '0px', borderRadius: '0px' }}>
        <h4 id="white papers" style={{ textAlign: 'center', color: '#333', textTransform: 'uppercase' }}>White Papers</h4>
        <InlineConnectedImages />
        </p>

        <p style={{ backgroundColor: 'rgb(164, 230, 245)', padding: '20px', color: '#333', margin: '0px', borderRadius: '0px' }}>
        <h4 id="contact" style={{ textAlign: 'center', color: '#333', textTransform: 'uppercase' }}>Contact Us</h4>
        <ContactInfo /> 
        </p>        
      
      </div>
    </div>
  );
}

export default App;







