import React from 'react';
import './ContactInfo.css';  // Import the CSS for styling

function ContactInfo() {
  return (
    <div className="contact-info">
      <p>Grapes.Cloud LLC</p>
      <p>CALL: <a href="tel:+1234567890">+1 312-909-5862</a></p>
      <p>EMAIL: <a href="mailto:info@example.com">info@grapes.cloud</a></p>
    </div>
  );
}

export default ContactInfo;