import React from 'react';


function NavBar() {

  return (
    <nav>
      <ul style={{ listStyleType: 'none', margin: 0, padding: 0, overflow: 'hidden', backgroundColor: 'rgb(115, 206, 226)' }}>
        {/* Logo Link */}
        <li style={{ float: 'left' }}>
        <img src="/logo.png" alt="Logo" style={{ height: '50px', marginRight: '10px', verticalAlign: 'middle' }} />
        </li>
        {/* About Link */}
        <li style={{ float: 'left' }}>
          <a href="#about" style={{ display: 'block', color: 'black', textAlign: 'center', padding: '14px 16px', textDecoration: 'none' }}>
            About
          </a>
        </li>
        {/* White Papers Link */}
        <li style={{ float: 'left' }}>
          <a href="#white papers" style={{ display: 'block', color: 'black', textAlign: 'center', padding: '14px 16px', textDecoration: 'none' }}>
            White Papers
          </a>
        </li>
        {/* Contact Link */}
        <li style={{ float: 'left' }}>
          <a href="#contact" style={{ display: 'block', color: 'black', textAlign: 'center', padding: '14px 16px', textDecoration: 'none' }}>
            Contact Us
          </a>
        </li>
      </ul>
    </nav>
  );
}



export default NavBar;