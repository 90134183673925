import React from 'react';
import './InlineConnectedImages.css';  // Import the CSS for styling

function InlineConnectedImages() {
  return (
    <div className="image-row">
      <a href="https://aws.amazon.com/blogs/database/how-sgk-simplified-the-data-structure-of-a-highly-dynamic-workload-by-migrating-from-an-rdbms-to-amazon-dynamodb/" target="_blank" rel="noopener noreferrer">
        <img src="/image1.png" alt="Image 1" />
      </a>
      <div className="line"></div>
      <a href="https://aws.amazon.com/blogs/industries/how-sgk-reduced-operating-costs-by-83-with-noops-serverless-microservices/" target="_blank" rel="noopener noreferrer">
        <img src="/image2.png" alt="Image 2" />
      </a>
      <div className="line"></div>
      <a href="https://aws.amazon.com/blogs/compute/implementing-dynamic-etl-pipelines-using-aws-step-functions/" target="_blank" rel="noopener noreferrer">
        <img src="/image3.png" alt="Image 3" />
      </a>
      <div className="line"></div>
      <a href="https://aws.amazon.com/blogs/compute/implementing-dynamic-etl-pipelines-using-aws-step-functions/" target="_blank" rel="noopener noreferrer">
        <img src="/image4.png" alt="Image 4" />
      </a>
      <div className="line"></div>
      <a href="https://aws.amazon.com/blogs/compute/implementing-dynamic-etl-pipelines-using-aws-step-functions/" target="_blank" rel="noopener noreferrer">
        <img src="/image5.png" alt="Image 5" />
      </a>
    </div>
  );
}

export default InlineConnectedImages;

